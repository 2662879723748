<template>
  <div id="area" class="area pb-24 sm:pb-8 flex w-full bg-white">
    <div class="grid w-full">
      <div class="flex box-border w-full justify-center items-center content-center overflow-y-scroll px-4">
        <div class="w-full text-center items-center content-center place-items-center place-content-center">
          <el-tabs v-model="activeTab"
                   class="tabs place-items-center"
                   @tab-click="handleClick"
          >
            <el-tab-pane v-for="stage in area" :label="stage.name" :name="stage.id">
              <div class="pt-10 flex box-border w-full justify-center gap-16 sm:pt-4">
                <img :src="assetUrl + stage?.image?.id + '?key=webp'"
                      class="w-[35%] h-fit object-cover aspect-square rounded-[2rem] sm:hidden" alt=""/>
                <div class="w-[40%] text-left sm:w-full sm:box-border px-4">
                  <img :src="assetUrl + stage?.image?.id + '?key=webp'"
                       class="w-full object-contain aspect-square md:hidden h-auto" alt=""/>
                  <div class="text-[3rem] sm:text-[2rem] sm:pt-2 text-left font-bold tracking-[0.15rem] sm:tracking-[0]">{{ stage.name }}</div>
                  <div class="text-justify text-[1rem] leading-[1.5625rem] pt-6">
                    {{stage.description}}
                  </div>
                  <div class="text-left font-semibold text-[1rem] leading-[1.5625rem] pt-6">
                    <span v-if="stage.talents.length !== 0">Artist Line Up:</span>
                  </div>
                  <div class="font-semibold text-[1rem] leading-[1.5625rem] h-[2.55rem] sm:h-auto">
                    <span v-for="talent in stage.talents"><span>{{talent.name}}</span>
                      <span v-if="talent.id !== stage.talents[stage.talents.length-1].id"> | </span>
                    </span>
                  </div>

                  <div v-if="stage.ticketLink !== null" class="flex pt-6"><a :href="stage.ticketLink" class="get-ticket plausible-event-name=Get+Ticket">Get Ticket</a></div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref} from "vue";

const props = defineProps<{
  event: any,
}>()
const assetUrl = `${import.meta.env.PUBLIC_API_URL}/assets/`;

const activeTab = ref(null);

const query = new URLSearchParams({
  "filter[event]" : props.event.id,
  "filter[status]" : "published",
  "sort": "order",
  "fields" : "id, name, description, ticketLink, image.id, talents.*, talents.images.directus_files_id, status",
});

const data = await fetch(`${import.meta.env.PUBLIC_API_URL}/items/EventArea?${query.toString()}`)
  .then((response) =>
    response.json()
  );

const area: any[] = data.data;
activeTab.value = area[0].id;

const handleClick = () => {

}

</script>

<style lang="scss">
.area {
  scroll-margin-top: 130px;
}

.el-tabs__nav-wrap::after {
  height: 0 !important;
}

.el-tabs__nav-scroll {
 @apply place-items-center grid;
}

.el-tabs__nav-prev {
  @apply mt-1;
  &.is-disabled {
    @apply hidden;
  }
}

.el-tabs__nav-next {
  @apply mt-1;
  &.is-disabled {
    @apply hidden;
  }
}

.el-tabs__item {
  @apply bg-white text-primary font-semibold text-[24px] active:text-primary
  hover:text-primary px-8 border-r-0 border-l-0 border-b border-t border-solid
  border-primary py-6 z-auto sm:text-[1.125rem] #{!important};
}
.el-tabs__item.is-active {
  @apply bg-primary text-white font-semibold text-[24px] active:text-white
  hover:text-white px-8 py-6 sm:text-[1.125rem] #{!important};
}
.el-tabs__active-bar {
  @apply h-0 #{!important};
}

.get-ticket {
  @apply flex bg-primary sm:text-[1rem] md:text-[1.125rem] sm:px-6 md:px-6 rounded-[48px] text-white text-center sm:py-3 md:py-2
  cursor-pointer font-semibold hover:text-white sm:px-14;
}
</style>
